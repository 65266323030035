import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  // url = "http://localhost:3000/api/";
  url = "";
  constructor(private http: HttpClient, private config: ConfigService) { }
  getConfig(){
    return this.http.get( this.config.getApiUrl()  +'master/config');
  }
  getSpecNames(){
    return this.http.get(this.config.getApiUrl()+'master/spec_names');
  }
  getEducationNames(){
    return this.http.get(this.config.getApiUrl()+'master/education_names');
  }
}
