import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url: string = "";
  myJsonToken: any;
  constructor(
    private config: ConfigService,
    private http: HttpClient,
    private router: Router
  ) {
    this.url = config.getApiUrl();
    const jsontoken: any =  localStorage.getItem('token');
    this.myJsonToken = JSON.parse(jsontoken);
   }

   public checkPatientLoggedIn(){
     if(localStorage.getItem('ptoken')){
        let token = localStorage.getItem('ptoken');
        let tt = JSON.parse(token+"");
        const now = new Date();    
        if(now.getTime() > tt.expiry){
          console.log("Session expired...");
          localStorage.removeItem('ptoken');
          localStorage.removeItem('user_cat_id');
          // localStorage.removeItem('uid');
          localStorage.removeItem('pid');
          // console.log( this.router.url); 
          this.router.navigate(['/login'], { queryParams: { url: this.router.url }  });
        }else{

        }
      }else{
        this.router.navigate(['/login'], { queryParams: { url: this.router.url }  });
        // this.router.navigateByUrl('/login');
      }
   }

   public checkDrLoggedIn(){
    if(localStorage.getItem('drtoken')){
       let token = localStorage.getItem('drtoken');
       let tt = JSON.parse(token+"");
       const now = new Date();    
       if(now.getTime() > tt.expiry){
         console.log("Session expired...");
         localStorage.removeItem('drtoken');
         localStorage.removeItem('user_cat_id');
         // localStorage.removeItem('uid');
         localStorage.removeItem('uid');
         // console.log( this.router.url); 
         this.router.navigate(['/doctor-login'], { queryParams: { url: this.router.url }  });
       }else{

       }
     }else{
       this.router.navigate(['/login'], { queryParams: { url: this.router.url }  });
       // this.router.navigateByUrl('/login');
     }
  }
}
